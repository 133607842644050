import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/Layout'
import ContactCallout from '../components/ContactCallout'
import Hero from '../components/Hero'
import TextBlock from '../components/TextBlock'

const ServicesPage = () => {
    const textBlockCopyColumnOne = [
        {
          para: "At Ancient Olives, we take great care to provide a unique and superior product and service. Our ancient and old growth olive trees have been hand selected by the partners for their historic and architectural aesthetics and value. We deliver them to your project and walk you through the steps on how to prepare for, plant, and maintain your historic olive trees for success."
        },
        {
            para: "<strong>Dedication to Service:</strong>"
        },
        {
          para: "We offer hand selected tree stock that has been carefully chosen by experience professionals. We have three principals when selecting olive trees:"
        },
        {
            para: "<ol><li>They must be old growth material and must have the character and charm that represents the true historic architecture of an old or ancient growth tree</li><li>We hand select trees from only the best orchards that afforded the trees the widest growth areas. Many orchards cram their trees into as little as 10’x10’ spacing. Our olive trees have been well spaced by a height + 30% of height ration between trees which promotes a horizontal growth and character reminiscent of the Italian countryside.</li><li>They must be strong, vigorous, and appear to be free of disease or character flaws that would weaken the tree or shorten its life span. These trees will provide character to your project for decades to come.</li></ol>"
        }
    ]

    const textBlockCopyColumnTwo = [
        {
            para: "Our owners are a family partnership that has been working together for many years. Eddie Flores and Sean Lockyer have been in the Landscaping and Design businesses for over four decades combined."
        },
        {
            para: "Eddie’s knowledge of olive tree anatomy and biology as well as his years of expertise in the landscape business makes him well suited to bringing you a quality product that is unsurpassed in the industry."
        },
        {
            para: "Sean Lockyer has been hand selecting olive trees for his various architectural projects for the last decade and a half. He brings his years of experience in Architecture to the detailed selection of the highest quality and most dramatic olive tree trunks and branch structure that your project requires."
        }
    ]

    const data = useStaticQuery(graphql`
        {
            file(relativePath: {eq: "services-bg.jpg"}) {
                name
                sharp: childImageSharp {
                gatsbyImageData(formats: [AUTO, WEBP, AVIF], layout: FULL_WIDTH)
                }
            }
        }
    `)

    const heroImg = data.file.sharp.gatsbyImageData

    return (
        <Layout>
            <Hero
                heading={'Specializing in Ancient and Old Growth Historical Trees'}
                heroImage={heroImg}
                styleClass="short"
            />
            <ServicePageWrapper>
                <div className="cols cols--50">
                    <div className="col">
                        <TextBlock
                            text={textBlockCopyColumnOne.map(text => <p dangerouslySetInnerHTML={{ __html: text.para }} />)}
                        />
                    </div>
                    <div className="col">
                        <TextBlock
                            text={textBlockCopyColumnTwo.map(text => <p dangerouslySetInnerHTML={{ __html: text.para }} />)}
                        />
                    </div>
                </div>

            </ServicePageWrapper>
            <ContactCallout heading={`Need help with choosing the right tree?`} />
        </Layout>
    )
}

export default ServicesPage

const ServicePageWrapper = styled.div`
    p,
    li {
        line-height: 1.618;
    }

    .col {
        p {
            max-width: 100%;
        }
    }
`;
