import styled from 'styled-components'

export const TextBlockElm = styled.div`
    padding: 0 12px;

    @media(min-width: 700px) {
        padding: 0 40px;
    }

    &.centered {
        text-align: center;

        p {
            margin-left: auto;
            margin-right: auto;
        }
    }

    p {
        margin: 0;
        line-height: 1.5;

        &:not(:first-child) {
            margin-top: 20px;
        }
        
        @media(min-width: 700px) {
            max-width: 75%;
        }
    }

    &.lg {
        p {
            font-size: 28px;
            font-weight: 300;
        }
    }

    li {
        margin-bottom: 12px;
    }
`;