import styled from 'styled-components'
import { theme, PrimaryButton } from '../../styles'

export const ContactCalloutElm = styled.section`
    position: relative;
    margin: 24px 12px;
    background: ${theme.tan};
    overflow: hidden;

    @media(min-width: 900px) {
        margin: 40px;
    }
`;

export const ContactCalloutCopy = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 24px 12px;
    z-index: 3;

    @media(min-width: 900px) {
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        padding: 100px 40px;
    }

    h2 {
        margin-bottom: 24px;
        color: ${theme.brown};
        font-size: 1.85em;
        line-height: 1.5;

        @media(min-width: 900px) {
            margin-bottom: 0;
            font-size: 2.778em;
        }
    }

    ${PrimaryButton} {
        @media(max-width: 700px) {
            display: block;
        }
    }
`;