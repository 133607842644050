import React from 'react'
import { TextBlockElm } from './TextBlockStyles'

const TextBlock = ({text, alignmentClass, styleClass}) => {
    return (
        <TextBlockElm className={`${alignmentClass} ${styleClass}`}>
            {text}
        </TextBlockElm>
    )
}

export default TextBlock
